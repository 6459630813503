.services {
    padding: 32px;
    background: #ffffff;

    .col-md-3 {
        padding: 0;
    }
    .card {
        height: 100%;
        border: none;
        border-radius: 0;
        padding: 10px;
        margin: 0;

        color: #343434;

        .card-header {
            min-height: 100px;
        }

        &:hover {
            background: #2B3990;
            color: #ffffff;


            .card-header {
                svg {
                    path {
                        fill: #fff;
                    }

                    rect {
                        fill: #2B3990;
                    }
                }


            }
            .card-body {
                h4 {
                    font-family: 'Lora', sans-serif;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    color: #fff;
                }

                p {
                    font-family: 'Lora', sans-serif;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    color: #fff;
                }
            }

            .card-footer {
                border: none;
                background: transparent;

                a {
                    font-family: 'Lora', sans-serif;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    color: #fff;

                    svg {
                        display: flex;
                        justify-content: flex-end;

                        g {
                            path {
                                fill: #fff;
                            }
                        }

                    }
                }
            }


        }

        &-header {
            border: none;
            background: transparent;
        }

        &-body {
            h4 {
                font-family: 'Lora', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                color: #343434;
            }

            p {
                font-family: 'Lora', sans-serif;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                color: #969696;
            }
        }

        &-footer {
            border: none;
            background: transparent;

            a {
                font-family: 'Lora', sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                display: flex;
                align-items: center;
                text-align: center;
                color: #969696;

                div {
                    width: 100%;
                    position: relative;

                    svg {
                        position: absolute;
                        top: -10px;
                        right: 0;

                    }
                }

            }
        }
    }

}
