.news-page {
    margin-top: 32px;

    .news-page-box {
        .items {
            .description {
                margin-top: 20px;
                margin-bottom: 20px;

                h4 {
                    font-family: 'Lora', sans-serif;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 22px;
                    line-height: 20px;

                    color: #343434;
                }

                p {
                    font-family: 'Lora', sans-serif;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    color: #343434;
                    opacity: 0.7;
                }


            }

        }
    }
}
