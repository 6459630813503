.paradigma {
    padding: 32px;
    background: #ffffff;


    p {
        color: #343434;
        font-family: 'Lora', sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        margin-top: 0;
        margin-bottom: 0;
    }


}
