.why-choose-us {
    padding: 32px;
    background: #ffffff;


    h2 {
        font-family: 'Lora', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        color: #343434;
        margin-bottom: 20px;

        @media screen and (max-width: 560px) {
            font-size: 28px;
        }



    }

    h3 {
        font-family: 'Lora', sans-serif;
        color: #2B3990;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 20px;

        @media screen and (max-width: 560px) {
            font-size: 24px;
        }

    }

    p {
        color: #343434;
        font-family: 'Lora', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
    }


}
