.our-goal {
    padding: 32px;
    background: #ffffff;


    h2 {
        font-family: 'Lora', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        color: #343434;
        margin-bottom: 0;

        @media screen and (max-width: 560px) {
            font-size: 28px;
        }


    }

    p {
        color: #343434;
        font-family: 'Lora', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        margin-top: 0;
        margin-bottom: 0;
    }


}
