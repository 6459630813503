.our-new-directions {
    display: flex;
    padding: 32px;
    background: #ffffff;


    h2 {
        font-family: 'Lora', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        color: #343434;
        margin-bottom: 20px;

        @media screen and (max-width: 560px) {
            font-size: 28px;
        }


    }

    h4 {
        color: #343434;
        font-family: 'Lora', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    p {
        color: #343434;
        font-family: 'Lora', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;

        strong {
            font-weight: 700;
        }

    }

}
