$primary-color: #2B3990;
$secondary-color: #1f2967;

.hero-main {
    margin-bottom: 3rem !important;
    overflow: hidden; /* Добавляем overflow: hidden для контейнера слайдера */

    .container {
        position: relative;
    }

    .swiper-slide {
        img {
            width: 100%;
            height: auto;

            @media screen and (max-width: 560px) {
                width: auto;
                height: 100%;
            }


        }
    }

    .description {
        //height: 100%;
        //position: relative;
        padding-right: 1rem !important;

        @media screen and (min-width: 980px) {
            padding-right: 11rem !important;
        }

        h1 {
            font-family: 'Lora', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 48px;
            color: #fff;
            margin-bottom: 20px;

            @media screen and (max-width: 560px) {
                font-size: 32px;
            }
        }

        .info {
            h2 {
                font-family: 'Lora', sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 38px;
                color: #fff;
                margin-bottom: 20px;


                @media screen and (max-width: 560px) {
                    font-size: 26px;
                }
            }

        }

        .feedback {
            display: flex;
            align-items: center;
            margin-top: 5rem !important;

            //position: absolute;
            //left: 0;
            //bottom: 0;

            @media screen and (max-width: 560px) {
                display: block;
                align-items: center;
                margin-top: 5rem !important;
            }

            button {
                min-width: 185px;
                text-align: center;
                padding: 15px 20px;
                color: #fff;
                border-radius: 0;
                border: none;
                font-family: 'Lora', sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                display: flex;
                align-items: center;
                text-align: center;
                background: $primary-color;
                transition: 200ms background-color;

                &:hover {
                    background: $secondary-color;
                }

                @media screen and (max-width: 560px) {
                    width: 100%;
                    justify-content: center;
                    margin-bottom: 20px;
                }
            }

            .phone-box {
                margin-left: 50px;
                svg {
                    padding-right: 15px;
                }

                .span-1 {
                    font-family: 'Lora', sans-serif;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    color: #fff;
                }

                .span-2 a {
                    font-family: 'Lora', sans-serif;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 19px;
                    color: #fff;
                    text-decoration: none;
                }
            }
        }
    }

    /* Стили для контейнера с текстом в слайде */
    .slide-content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
