.contact-info {
    h2 {
        font-family: 'Lora', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 64px;
        color: #343434;

        @media screen and (max-width: 560px) {
            font-size: 28px;
        }

    }


    &-box {
        padding: 32px;
        background: #ffffff;
        margin-bottom: 30px;

        .items {
            font-family: 'Lora', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #343434;


            svg {
                width: 32px;
                height: 32px;
                margin-right: 8px;

                @media screen and (max-width: 560px) {
                    width: 24px;
                    height: 24px;
                }
            }

            a {
                color: #343434;
                font-family: 'Lora', sans-serif;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                text-decoration: none;

                @media screen and (max-width: 560px) {
                    word-break: break-all;
                    font-size: 16px;
                }


                &:hover {
                    text-decoration: underline;

                }
            }

        }


    }

}



.contacts {
    padding: 32px;
    background: #ffffff;

    h2 {
        font-family: 'Lora', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        color: #343434;
    }

    p {
        font-family: 'Lora', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #969696;
    }

    label {
        font-family: 'Lora', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        color: #343434;

    }

    input, textarea {
        font-family: 'Lora', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #343434;

        padding: 10px 20px;

        border: 4px solid #F2F4F5;
        border-radius: 0;
        background: #F2F4F5;

        &:focus {
            border: 4px solid #F2F4F5;
            box-shadow: none;

        }
    }

    textarea {
        min-height: 130px;
    }

    button {
        font-family: 'Lora', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        padding: 15px 60px;
        border: none;
        color: #FFFFFF;
        background: #2B3990;

        transition: 200ms background-color;

        &:hover {
            background: #1f2967;
        }
    }

    .btn-outline-primary {
        font-family: 'Lora', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        padding: 5px 60px;
        border: 1px solid #2B3990;
        color: #2B3990;
        background: #fff;
        margin-bottom: 32px;
        border-radius: 0;

        transition: 200ms background-color;

        &:hover {
            color: #fff;
            background: #1f2967;
        }
    }

}
