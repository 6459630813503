footer  {
    margin-top: 120px;
    background: #343434;

    h5 {
        color: #FFF;
        text-align: center;
        font-family: Pacifico;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .contact-box {
        font-family: 'Lora';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;


        .phone {
            margin-top: 10px;
            margin-bottom: 5px;
        }

        a {
            font-family: 'Lora';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: rgba(255,255,255, .7);

            text-decoration: none;
        }
    }

    p {
        font-family: 'Lora', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: rgba(255,255,255, .7);

    }

    .nav {
        .nav-item {
            //width: 134px;
            display: flex;
            align-items: center;
            justify-content: center;

            transition: 200ms background-color;

            @media screen and (max-width: 560px) {
                width:100%;
            }


            &:hover {
                background: #2B3990;

                .nav-link {
                    color: #fff;
                }
            }


            &:last-child {
                border-right: none;
            }

            &.order {
                background: #2B3990;

                .nav-link {
                    font-family: 'Lora', sans-serif;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 13px;
                    text-align: center;

                    color: #fff;
                }
            }

            .nav-link {
                font-family: 'Lora', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 13px;
                text-align: center;
                color: #ffffff;
            }
        }
    }

}
