header {
    h5 {
        text-transform: uppercase;
        //filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    }
    .nav {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .nav-item {
            width: 140px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 16px;
            padding-right: 16px;
            padding-bottom: 6px;
            //padding-right: 32px;
            text-align: center;
            border-bottom: 2px solid transparent;

            //border-right: 1px solid #E0E0E0;

            transition: 200ms background-color;

            @media screen and (max-width: 560px) {
                border-right: none;
                width:100%;
            }

            &:hover {
                border-bottom: 2px solid #2B3990;

                .nav-link {
                    //color: #2B3990;
                }
            }

            &:last-child {
                border-right: none;

            }

            &.order {
                background: #2B3990;

                transition: 200ms background-color;

                &:hover {
                    background: #1f2967;
                }

                .nav-link {
                    font-family: 'Lora', sans-serif;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 15px;
                    text-align: center;

                    color: #FFFFFF;
                }
            }

            .nav-link {
                font-family: 'Lora', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 15px;
                text-align: center;
                color: #343434;

                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;

                @media screen and (max-width: 560px) {
                    font-size: 15px;
                    height: auto;
                }


            }
        }
    }

    .dropdown {
        &-toggle {
            color: #343434;
            font-family: 'Lora', sans-serif;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            text-decoration: none;
            outline: none;

        }

        &-menu {
            --bs-dropdown-min-width: 50px;
        }

    }
}
