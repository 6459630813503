body {
    font-family: 'Lora', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #343434;
    background-color: #F2F4F5;
    z-index: 999;


}

section {
    margin-bottom: 40px;
}

.card:hover  {
    transition: 300ms linear;
}

.modal {
    .close {
        width: 32px;
        height: 32px;
        position: absolute;
        top: 20px;
        right: 20px;
        padding: 0;
        background: transparent;

        &:hover {
            svg {
                transform: rotate(360deg) translateX(0px);
                transition: 300ms all;

            }
            background: transparent;
        }
    }

    .modal-content {
        background-color: transparent;
    }

    @media (min-width: 992px) {
        &.modal-lg-90 {
            .modal-dialog {
                max-width: 90%;
                //margin: 10%  auto;
            }
        }
    }


    &.modal-dialog-slideup {
        .modal-dialog {
            transform: translateY(100%);
        }

        &.show {
            .modal-dialog {
                transform: translateY(0);
            }
        }
    }


}



.swiper-slide {
    position: relative;
}
.slide-text {
    //position: absolute;
    top: 25%;
    left: 0;
    //background-color: rgba(0, 0, 0, 0.7);
    //color: #fff;
    //padding: 20px;
}
