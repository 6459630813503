@import url('https://fonts.googleapis.com/css2?family=Lora:wght@600;700&display=swap');
@import url('https://fonts.cdnfonts.com/css/sf-ui-display');

@import '~bootstrap/scss/bootstrap';

@import "@fancyapps/ui/dist/fancybox/fancybox.css";

@import "general";
@import "section/index/header";
@import "section/index/hero-main";
//@import "section/index/why-so";
//@import "section/index/law-office-is-formed";
//@import "section/index/about-me";
//@import "section/index/our-credo";
//@import "section/index/subscribe";
//@import "section/index/expertise";
//@import "section/index/services";
//@import "section/index/benefit";
//@import "section/index/partners";
//@import "section/index/philosophy";
//@import "section/index/anonce";
//@import "section/index/contacts";
@import "section/index/footer";

//@import "section/services/index";
//@import "section/qualification/index";


@import "section/about";
@import "section/our-new-directions";
@import "section/why-choose-us";
@import "section/our-goal";
@import "section/paradigma";
@import "section/services";
@import "section/services-page";
@import "section/contacts";
@import "section/anonce";
@import "section/news";
@import "section/news/index";
@import "section/charity-foundation";




